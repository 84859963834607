/* ========================================

    support
        サポートのパーツ

    INDEX
        - area
        - list

======================================== */

/* ========================================
    area
======================================== */
.support-area {
    
}


/* ========================================
    list
======================================== */
.support-list {
    &__item {
        
    }
}