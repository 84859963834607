// ========================================
//
//      mixins
//          何度も使う重複する記述を定義
//    
//      INDEX
//          - float
//          - after
//          - center
//          - icon
//          - font
//          - ratio
//          - hover
//          - transition
//          - bg
//          - media query
//
// ========================================


// ========================================
//    flex
// ========================================
@mixin flex() {
    display: flex !important;

    &__jc {
        &-c {
            justify-content: center;
        }

        &-sb {
            justify-content: space-between;
        }
    }
    
    &__d-rr {
        flex-direction: row-reverse;
    }

    &__ai-c {
        align-items: center;
        height: 100%;
    }
    
    @for $i from 0 through 10 {
        $tmp: $i*10;
      
        // margin
        .w#{$tmp} {
            width: #{$tmp}% !important;
        }
    }
}


// ========================================
//    width
// ========================================
@mixin contentWidth() {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: $MAX_W;
}


// ========================================
//    float
// ========================================
// floatの解除
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


// ========================================
//  after
//=========================================
@mixin after() {
    content: "";
    display: block;
    position: absolute;
}


// ========================================
//  center
//=========================================
@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin positionCenter() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

@mixin flexCenter() {
    display: flex;
    justify-content: center;
    align-items: center;
}

// ========================================
//  icon
//  - 商品につけるアイコン用（例：Newマークなど
//=========================================
@mixin itemIcon($top, $right, $width, $height, $bgColor, $color) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: $top;
    right: $right;
    width: $width;
    height: $height;
    border-radius: 100%;
    background-color: $bgColor;
    color: $color;
}

// ========================================
//  font
//=========================================
@mixin font-eng {
    font-family: $ENG_FONT_FAMILY;
}

@mixin font-sub {
    font-family: $SUB_FONT_FAMILY;
}


// ========================================
//  ratio
//=========================================
// padding-topを使って比率をキープしたボックスを作る
// デフォルトは16:9のボックス（youtubeなどはこれ）
// 例）
//      include ratioBox();      // 16:9 -> 56.25%
//      include ratioBox(16, 9); // 16:9 -> 56.25%
//      include ratioBox(9, 16); // 9:16 -> 177.7777777778%
//      include ratioBox(1, 1);  // 1:1  -> 100%
@mixin ratioBox($width: 16, $height: 9, $par: 100%) {
    &:before {
        content: "";
        display: block;
        padding-top: ($height / $width) * $par;
    }
}


// ========================================
//  hover
//=========================================
@mixin hover () {
    &:hover {
        @include mq-pc {
            cursor: pointer;
            @include transition;
            opacity: 0.5;
            @content;
        }
    }
}

// ========================================
//    transition
//      アニメーションの感覚を共通で設定する
// ========================================
@mixin transition ($duration: $DEFAULT_DURATION) {
    transition: $duration;
}


// ========================================
//  bg
//=========================================
@mixin bgCover ($image_path: '') {
    background-image: url($IMG+$image_path);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin bgContain ($image_path: '') {
    background-image: url($IMG+$image_path);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin bgRepeat ($image_path: '', $width: 50px, $height: 50px) {
    background-image: url($IMG+$image_path);
    background-size: $width $height;
    background-repeat: repeat;
}


// ========================================
//    media query
// ========================================
// ----------------------------------------
// ブラウザ幅に応じて反映するスタイルを変えるmixin
// これを使えばあちこちに同じクラス名を書かなくて済む

// PC幅以上 のみ有効なスタイル
//      $is_pc  true  => PC幅のみ
//              false => PC幅以外
//
@mixin mq-xl($is_pc: true) {
    @if $is_pc {
        // デフォルト。PC幅以上
        @media screen and (min-width: $BP_L + 1) {
            @content;
        }
    } @else {
        // TB幅以下
        @media not screen and (min-width: $BP_L + 1) {
            @content;
        }
    }
}

// 
// TB 〜 PC幅 のみ有効なスタイル
//      $is_pc  true  => PC幅のみ
//              false => PC幅以外
//
@mixin mq-l($is_pc: true) {
    @if $is_pc {
        @media screen and (min-width: $BP_M + 1) and (max-width: $BP_L) {
            @content;
        }
    } @else {
        @media not screen and (min-width: $BP_M + 1) and (max-width: $BP_L) {
            @content;
        }
    }
}

//
// SP 〜 TB幅 のみ有効なスタイル
//      $is_tb  true  => TB幅のみ
//              false => TB幅以外
//
@mixin mq-m($is_tb: true) {
    @if $is_tb {
        // デフォルト。SP 〜 TB幅のみ有効
        @media screen and (min-width: $BP_S + 1) and (max-width: $BP_M) {
            @content;
        }
    } @else {
        // TB幅以外
        @media not screen and (min-width: $BP_S + 1) and (max-width: $BP_M) {
            @content;
        }
    }
}

//
// SP幅以下 のみ有効なスタイル
//      $is_sp  true  => SP幅のみ
//              false => SP幅以外
//
@mixin mq-s($is_sp: true) {
    @if $is_sp {
        // 初期値。SP幅のみ
        @media screen and (max-width: $BP_S) {
            @content;
        }
    } @else {
        // SP幅以外
        @media not screen and (max-width: $BP_S) {
            @content;
        }
    }
}

//
// iPhone5サイズのみ有効
//
@mixin mq-iphone5($is_iphone5: true) {
    @if $is_iphone5 {
        @media screen and (max-width: 320px) {
            @content;
        }
    } @else {
        @media not screen and (max-width: 320px) {
            @content;
        }
    }
}

//
// TB, SPのみ
//
@mixin mq-mb($is_mobile: true) {
    @if $is_mobile {
        @media screen and (max-width: $BP_M) {
            @content;
        }
    } @else {
        @media not screen and (max-width: $BP_M) {
            @content;
        }
    }
}

//
// PC以上のみ
//
@mixin mq-pc($is_pc: true) {
    @if $is_pc {
        @media screen and (min-width: $BP_M + 1) {
            @content;
        }
    } @else {
        @media not screen and (min-width: $BP_M + 1) {
            @content;
        }
    }
}
