.js-accordion {
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    background: #FFF;
    margin-top: 20px;

	&-title {
        line-height: 48px;
        padding-left: 20px;
		text-align: left;
		width: 100%;
		color: #E3E3E3;
		cursor: pointer;
		position: relative;

		&:after {
			display: block;
			content: "";
			width: 7px;
			height: 7px;
			background: transparent;
			border-right: 2px solid $C_MAIN;
			border-bottom: 2px solid $C_MAIN;
			transform: rotate(45deg);
			position: absolute;
			top: 0;
			bottom: 0;
			right: 15px;
            margin: auto;
            transition: 0.2s;

		}
        &.js-open {
            &:after {
                transform: rotate(-135deg);
            }
        }
	}

	&-body {
		display: none;
		text-align: center;
	
		&.is-open {
            display: block;
            padding-bottom: 10px;
		}
	}

	&-link {
        padding: 5px 0;
        width: 100%;
        display: inline-block;
        color: $C_BLACK;
        text-align: left;
        padding-left: 20px;
	}
}