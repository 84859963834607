/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special
        - cart

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 2px solid $C_MAIN!important;
    background-color: $C_MAIN;
    color: $C_WHITE;
    border-radius: 100px!important;
    max-width: 320px;
    min-height: 60px;
    font-size: $BTN_FONT_SIZE;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    @include transition;

    &.disable {
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.3;
    }

    &:not(.disable) {
        @include hover {
            background-color: $C_WHITE;
            color: $C_MAIN;
            opacity: 1;
        }
    }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    @extend %normal-btn;
    background-color: $C_WHITE;
    color: $C_MAIN;

    &:not(.disable) {
        @include hover {
            background-color: $C_MAIN;
            color: $C_WHITE;
            opacity: 1;
        }
    }
}

// 幅いっぱいのサイズ
%btn-100 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block!important;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
        よく使うボタン
======================================== */
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;
}

.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin-left: auto;
    margin-right: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.btn-special-100 {
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %special-btn;
    @extend %btn-50;
}


/* ========================================
    cart
======================================== */
.cart-btn {
    @extend %normal-btn;
    @extend %btn-100;

    .cart-icon {
        display: inline-block;
        padding-bottom: 5px;
        width: 24px;
        vertical-align: middle;
        fill: $C_MAIN;
    }

    &:hover {
        .cart-icon {
            fill: $C_SUB1;
        }
    }
}


/* ========================================
    more
        もっと見るボタン
======================================== */
.btn-more {
    position: relative;
    display: block;
    padding: 10px;
    margin: 20px auto;
    width: 100px;
    border-radius: 5px;
    background-color: $C_BLACK;
    color: $C_WHITE;
    @extend %small;
    font-weight: bold;
    text-align: center;

    i {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}


.btn-arrow {
    position: relative;
    padding-left: 60px;
    padding-right: 60px;

    &:before {
        content: '\f105';
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
        font-family: $ICON_FONT_FAMILY;
    }
}