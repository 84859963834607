/* ========================================

    common
        formに使えるパーツ

    INDEX
        - form

======================================== */

/* ========================================
    form
======================================== */
input[type="submit"],
input[type="button"] {
    border-radius: 0;
    appearance: button;
    box-sizing: border-box;
    cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
    display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
    outline-offset: -2px;
}

input[type="text"],
input[type="email"],
input[type="button"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="password"],
textarea {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0.4em;
    background-color: inherit;
    border: 1px solid $C_GRAY;
    color: $C_FONT;
    border-radius: 0;
    box-sizing: border-box;
    outline: none;
    @include transition;
    
    &:focus {
        background-color: $C_BG;
    }
}

input[type="text"],
textarea {
    width: 100%;
}


input[type="radio"],
input[type="checkbox"] {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    outline: none;
    border: 1px solid $C_GRAY;
    appearance: none;
    transition: all 0.16s ease-out 0s;
    
    &:checked {
        &:before,
        &:after {
            content: '';
            @include center;
            background-color: $C_MAIN;
        }
        
        &:before {
            width: 50%;
            height: 50%;
        }

        &:after {
            animation: click-wave 0.64s;
        }
    }
}

input[type="radio"] {
    border-radius: 100%;
    
    &:checked {
        &:before,
        &:after {
            border-radius: 100%;
        }
    }
}

// 2個横並びの時
label + label {
    input[type="radio"],
    input[type="checkbox"] {
        margin-left: 16px;
    }
}


select {
    appearance: none;
    border-radius: 0;
    outline: none;
    padding: 10px 20px;
    background-color: inherit;
    border: 1px solid $C_GRAY;
    cursor: pointer;
    
    &:disabled, option {
        color: $C_FONT;
    }
    
    &::-ms-expand {
        display: none;
    }
}

.choise-select {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 50px;
    width: 100%;
    min-height: 40px;
    background-color: $C_GRAY3;
    font-size: 1.3rem;
    overflow: hidden;

    &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        border-top: 1px solid $C_FONT;
        border-right: 1px solid $C_FONT;
        transform: rotate(135deg);
    }  

    &__title {
        color: $C_FONT;
        padding: 15px 20px;
        border-right: 1px solid $C_BG;
    }

    select {
        cursor: pointer;
        width: 100%;
        padding-right: 60px;
        text-indent: 0.01px;
        border: none;
        appearance: none;
        color: $C_FONT;
    
        &:disabled, option {
            color: $C_FONT;
        }
        
        &:-ms-expand {
            display: none;
        }
    }
}


.js-selectFile {
    .js-upload {
        display: none;
    }

    .choise-file-btn {
        @extend %normal-btn;
        @extend %btn-100;
    }
}


.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .input-text {
        display: inline-block;
        margin-left: 8px;
        max-width: 90%;
        vertical-align: middle;
        word-break: break-word;

        .sold-out-text {
            @include positionCenter;
            background-color: rgba($C_SUB1, 0.8);
            color: $C_MAIN;
            text-align: center;
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;
            transform: rotate(-10deg);

            @include mq-pc() {
                width: 80px;
                height: 16px;
                font-size: 0.85rem;
            }

            @include mq-mb() {
                width: 100px;
                height: 24px;
            }
        }
    }

    input[type="radio"]:checked + .check-btn {
        background-color: $C_SUB1;
    }
}