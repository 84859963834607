// ========================================
// INDEX
// - layout
// - slider
// - detail
// ========================================

$slider: 500px;

// ========================================
// - layout
// ========================================
.item-detail-content {
    margin-bottom: 7rem;

    @include mq-pc {
        display: flex;
        justify-content: space-between;
    }
}




// ========================================
// - slider
// ========================================
.item-detail {
    &_photo {
        position: relative;
        margin-bottom: 3.5rem;

        @include mq-pc {
            width: 50%;
            max-width: $slider;
        }

        @include mq-mb {
            text-align: center;
        }

        .icon__new {
            @include itemIcon($top: -15px, $right: 10px, $width: 60px, $height: 60px, $bgColor: $C_RED, $color: $C_WHITE);
            z-index: 10;
            font-size: 1.3rem;
        }

        .swiper {
            &-button {
                @include mq-mb {
                    display: none;
                }

                &-next,
                &-prev {
                    font-size: 4rem;
                    color: $C_FONT;
                }
    
                &-next {
                    @include mq-pc {
                        right: -$ARROW_SIZE - ($ARROW_SIZE / 2);
                    }
                }
    
                &-prev {
                    @include mq-pc {
                        left: -$ARROW_SIZE - ($ARROW_SIZE / 2);
                    }
                }
            }
        }
    }

    &_data {
        @include mq-pc() {
            padding-left: 90px;
            width: 50%;
        }
    }


    &__tabs {
        display: table;
        width: 100%;
        background-color: $C_GRAY3;

        li {
            display: table-cell;
            width: 33.3%;

            &.active {
                background-color: $C_GRAY;

                a {
                    color: $C_SUB1;
                }
            }
        }

        a {
            display: block;
            padding: 5px 10px;
            text-align: center;
            color: $C_GRAY;
            font-size: 1.25rem;
        }
    }

    &-desc {
        display: none;
        padding: 20px;
        margin-bottom: 1.75rem;
        font-size: 1.4rem;
        border: 2px solid $C_MAIN;
        border-top: none;
        background-color: $C_MAIN;

        @include mq-mb {
            padding: 10px;
        }
    }

    &__row {
        width: 100%;
    }
}


// ========================================
// - detail
// ========================================
.item-name {
    margin-bottom: 20px;
    font-family: $ENG_FONT_FAMILY;
    font-size: 2.8rem;
    font-weight: bold;
    color: $C_MAIN;
}

.price-cont {
    margin-top: 20px;
    margin-bottom: 40px;
    color: $C_GRAY;
    font-size: 1.8rem;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;
}


.item-detail-categories {
    @include mq-mb {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }
}

.item-detail-notice {
    margin-bottom: 1rem;
}

.item-detail-quantity {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 20px;

    .selModalButton {
        width: auto;
        background-color: $C_WHITE;
        border: solid 2px $C_GRAY2;
        &:after {
            display: none;
        }
    }
}


.item-detail-cart-btn-cont {
    @include mq-pc {
        margin-top: 1.75rem;
    }

    @include mq-mb {
        width: 100%;
    }

    &.disable {
        margin-top: 0;
    }
}

.submit-message {
    margin-bottom: 40px;
}

.item-category-list {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;

    @include mq-mb {
        justify-content: space-between;
        width: 100%;
    }

    li {
        margin-bottom: 10px;
        border: 1px solid $C_MAIN;

        @include mq-pc {
            margin: 5px;
            white-space: nowrap;
        }

        @include mq-mb {
            width: 100%;
        }
    }
}

.choice-list {
    &__item {
        padding: 20px 0;
        border-bottom: 1px solid $C_MAIN;
    }

    &__form {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__size {
        @include mq-pc {
            width: 30%;
            padding-right: 10px;
            text-align: center;
        }

        @include mq-mb {
            width: 60%;
            padding-right: 10px;
        }
    }

    .each-btn {
        max-width: 200px;
        width: 40%;

        @include mq-mb {
            margin-top: 10px;
            margin-left: auto;
        }

        .btn-100,
        .cart-btn {
            margin: auto;
            padding: 5px;
    
            @include mq-pc {
                font-size: 1.4rem;
            }
    
            @include mq-mb {
                font-size: 1.2rem;
    
                .cart-icon {
                    width: 16px;
                }
            }
        }
    }
}


.disable-buy-text {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    background-color: $C_SUB1;
    color: $C_WHITE;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
}


