body.selModalBody {
    overflow: hidden;
}
  
.selModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    display: none;

    &Overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: rgba($C_BLACK, 0.5);
        width: 100%;
        height: 100%;
    }

    .selModalInner {
        @include center;
        background-color: $C_BG;
        width: 95%;
        max-width: 600px;
        max-height: 95%;
        overflow-y: hidden;

        .selModalHeader {
            height: 50px;
            background-color: $C_BLACK;
        }

        .selModalList {
            overflow-scrolling: touch;
            overflow-y: auto;
        }
        
        ul {
            background-color: $C_WHITE;

            li {
                position: relative;
                display: block;
                padding-top: 10px;
                padding-left: 20px;
                padding-right: 30px;
                padding-bottom: 10px;
                border-bottom: 1px solid $C_GRAY2;
                cursor: pointer;

                img {
                    height: auto;
                    vertical-align: middle;
                    margin-right: 5px;
                    max-width: 40px;
                }

                &[data-value=""] {
                    display: none;
                }

                &.totch {
                    background-color: $C_GRAY3;
                }

                &.selected {
                    background-color: $C_SUB1;
                    color: $C_WHITE;

                    &:before {
                        position: absolute;
                        content: '';
                        display: block;
                        height: 100%;
                        top: 0;
                        left: 0;
                        width: 4px;
                        background-color: $C_MAIN5;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    display: block;
                    width: 6px;
                    height: 6px;
                    margin-top: -3px;
                    border-top: 1px solid;
                    border-right: 1px solid;
                    transform: rotate(45deg);
                }
            }
        }
    }

    &CloseIcon {
        position: absolute;
        top: 15px;
        right: 10px;
        width: 25px;
        height: 25px;
        cursor: pointer;
      
        span {
            @include center;
            display: block;
            width: 100%;
            height: 100%;

            &:before, &:after {
                content: '';
                @include positionCenter
                width: 100%;
                height: 3px;
                background-color: $C_BG;
            }
        
            &:before {
                transform: rotate(-45deg);
            }
        
            &:after {
                transform: rotate(45deg);
            }
        }
    }

    &Button {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 50px;
        width: 100%;
        min-height: 40px;
        background-color: $C_GRAY3;
        font-size: 1.3rem;
        overflow: hidden;
    
        &:after{
            content: "";
            position: absolute;
            top: 0;
            right: 20px;
            bottom: 0;
            margin: auto;
            width: 6px;
            height: 6px;
            border-top: 1px solid $C_FONT;
            border-right: 1px solid $C_FONT;
            transform: rotate(135deg);
        }  
    }
}