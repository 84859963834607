.swiper {
    &-custom-parent {
        position: relative;
    }

    &-slide {
        &__inner {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
        }
    
        &__img {
            width: 100%;
        }
    }

    &-button {
        &-next,
        &-prev {
            &:after {
                display: none;
            }
        }
    }

    &-pagination-thumbs {
        margin-top: 10px;
        margin-left: -5px;
        margin-right: -5px;
    
        .swiper-pagination-thumb {
            position: relative;
            display: inline-block;
            margin: 5px;
            padding-top: 14%;
            width: 14%;
            @include bgContain ($image_path: '');
            border: 1px solid $C_GRAY2;
            outline: 3px solid transparent;
            background-color: $C_BG;
            box-sizing: content-box;

            &.swiper-pagination-thumb-active {
                outline: 3px solid $C_MAIN;
            }
        }
    }
}


.js-thumb-slider {
    .swiper {
        &-slide {
            width: 100%;
            padding-top: 100%;
            overflow: hidden;
            border: 1px solid $C_GRAY2;
    
            img:not(.blank-img) {
                display: block !important;
                @include center;
                width: 100%;
                object-fit: contain;
            }
        }
    }
}