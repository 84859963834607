// ========================================
//  aem scss templates
//      ver 2.0.5
// ======================================== 
@charset 'UTF-8';


/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
// @import "common/normalize";
// @import "common/input";
@import "common/base";
@import "common/parts";
@import "common/animation";
// @import "common/format_migration";


/* ========================================
    modules
        外部プラグインなどで使うもの
======================================== */
@import "modules/selmodal";
@import "modules/fancybox";
@import "modules/swiper";
@import "modules/accordion";
// @import "modules/bxslider_reset";
// @import "modules/card";
// @import "modules/colorbox_reset";
// @import "modules/photoswipe";
// @import "modules/share_buttons";


/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/sns";
@import "components/nav";
@import "components/support";
@import "components/pager";
@import "components/title";
@import "components/btn";
@import "components/list";
@import "components/movie";
@import "components/form";
@import "components/post-entry";


/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index";
@import "pages/goods_detail";