// ========================================
//
//      variables
//          サイト全体で使う変数定義
// 
//      INDEX
//          - break point
//          - color
//          - path
//          - easing
//
// ========================================

$FONT_SIZE: 1.4rem;

$LARGE: 1.8rem;
$X_LARGE: 4rem;

$BTN_FONT_SIZE: $LARGE;

$MAX_W: 980px;

$L_DIFF: 45px;
$M_DIFF: 20px;
$S_DIFF: 16px;

$HEADER_T_H: 80px;
$HEADER_B_H: 56px;
$HEADER_H: $HEADER_T_H + $HEADER_B_H;

$FOOTER_T_H: 100px;
$FOOTER_B_H: 40px;
$FOOTER_H: $FOOTER_T_H + $FOOTER_B_H;

$ARROW_SIZE: 30px;

$PAGE_TOP: 40px;
$PAGE_TOP_BORDER: 2px;

// ========================================
//    break point
// ========================================
$BP_L: 1080px; // PC
$BP_M: 768px;  // TB
$BP_S: 480px;  // SP


// ========================================
//    color
// ========================================
$C_WHITE: white;
$C_GRAY: #959595;
$C_GRAY2: #E3E3E3;
$C_GRAY3: #F5F5F5;
$C_BLACK: #000000;
$C_RED: #D6000D;
$C_GREEN: #00B44F;
$C_BLUE: #2B3EAC;
$C_YELLOW: #FFE200;
$C_PINK: pink;
$C_PURPLE: purple;

$C_MAIN: #1a2148; // サイトのメインカラー
$C_MAIN2: lighten($C_MAIN, 30%); // サイトのメインカラーを基準に明るくしたもの1
$C_MAIN3: lighten($C_MAIN, 15%); // サイトのメインカラーを基準に明るくしたもの2
$C_MAIN4: darken($C_MAIN, 10%); // サイトのメインカラーを基準に暗くしたもの1
$C_MAIN5: darken($C_MAIN, 20%); // サイトのメインカラーを基準に暗くしたもの4
$C_MAIN6: darken($C_MAIN, 30%); // サイトのメインカラーを基準に暗くしたもの3

$C_SUB1: #292929; // サイトのサブカラー1
$C_SUB2: $C_BLACK; // サイトのサブカラー2

$C_FONT: $C_BLACK;
$C_BG: $C_WHITE;

$C_LINK: $C_RED; // リンクの文字色
$C_HOVER: #2D41A6; // hoverの色


// ========================================
//    path
// ========================================
$IMG: '/images/';


// ========================================
//    font
// ========================================
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
$MAIN_FONT_FAMILY: "ヒラギノ角ゴ ProN W3" , "Hiragino Kaku Gothic ProN" , "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", "Meiryo", sans-serif; // サイト全体
$ENG_FONT_FAMILY: "Roboto", $MAIN_FONT_FAMILY; // 好きなのに変えてください
$SUB_FONT_FAMILY: $MAIN_FONT_FAMILY; // 好きなのに変えてください
$ICON_FONT_FAMILY: 'Font Awesome 5 Free'; // 好きなのに変えてください


// ========================================
//    easing
// ========================================
// 以下のサイトからイージングを全て定義しました。
// http://easings.net/ja

// << 補足説明 >>
//     ＜スピード感＞
//          IN = 徐々に加速し、終わり際が一番早い
//          OUT = 最初が早くて、徐々に減速
//          IN_OUT = 徐々に加速し、中間が一番早く、徐々に減速する
// 
//      ＜緩急の大きさ順＞
//          なだらか ... SINE < QUAD < CUBIC < QUART < QUINT < EXPO ... 急
//
//      ＜その他＞
//          LINEAR -> 等速
//          CIRC   -> 加速、減速が大きなカーブ
//          BACK   -> 一度通り過ぎてから戻ってくる

$DEFAULT_DURATION: 0.3s;
$EASE_LINEAR: linear;

$EASE_IN_SINE: cubic-bezier(0.47, 0, 0.745, 0.715);
$EASE_OUT_SINE: cubic-bezier(0.39, 0.575, 0.565, 1);
$EASE_IN_OUT_SINE: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$EASE_IN_QUAD: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$EASE_OUT_QUAD: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$EASE_IN_OUT_QUAD: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$EASE_IN_CUBIC: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$EASE_OUT_CUBIC: cubic-bezier(0.215, 0.61, 0.355, 1);
$EASE_IN_OUT_CUBIC: cubic-bezier(0.645, 0.045, 0.355, 1);

$EASE_IN_QUART: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$EASE_OUT_QUART: cubic-bezier(0.165, 0.84, 0.44, 1);
$EASE_IN_OUT_QUART: cubic-bezier(0.77, 0, 0.175, 1);

$EASE_IN_QUINT: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$EASE_OUT_QUINT: cubic-bezier(0.23, 1, 0.32, 1);
$EASE_IN_OUT_QUINT: cubic-bezier(0.86, 0, 0.07, 1);

$EASE_IN_EXPO: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$EASE_OUT_EXPO: cubic-bezier(0.19, 1, 0.22, 1);
$EASE_IN_OUT_EXPO: cubic-bezier(1, 0, 0, 1);

$EASE_IN_CIRC: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$EASE_OUT_CIRC: cubic-bezier(0.075, 0.82, 0.165, 1);
$EASE_IN_OUT_CIRC: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$EASE_IN_BACK: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$EASE_OUT_BACK: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$EASE_IN_OUT_BACK: cubic-bezier(0.68, -0.55, 0.265, 1.55);
