/* ========================================
    
    header
        ヘッダーのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    &__top {
        position: relative;
        background-color: $C_BLACK;
        z-index: 1;

        @include mq-pc {
            height: $HEADER_T_H;
        }

        @include mq-mb {
            height: $HEADER_T_H - $M_DIFF;
        }
    }

    &__bottom {
        background-color: $C_SUB1;

        @include mq-pc {
            height: $HEADER_B_H;
        }

        @include mq-mb {
            height: $HEADER_B_H - $S_DIFF;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        max-width: 120px;

        a {
            color: $C_BG;
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;

            @include mq-pc {
                font-size: 3.2rem;
            }
    
            @include mq-mb {
                font-size: 2,4rem;
            }
        }
    }
}


.search {
    @include mq-mb {
        display: none;
        position: fixed;
        top: $HEADER_T_H - 20px;
        left: 0;
        padding: 20px;
        width: 100%;
        background-color: $C_MAIN4;
        @include transition;
        transform: translateY(-100%);
        overflow-y: scroll;
    }

    &.js-active {
        transform: translateY(0);
    }

    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 30px;
        background-color: $C_BG;
        border-radius: 100px;
        max-height: 30px;

        i {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
    }
    
    input[type="search"] {
        border: none;
        background: none !important;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
}