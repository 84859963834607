/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper

======================================== */

/* ========================================
    wrapper
======================================== */
.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @include mq-pc {
        padding-top: $HEADER_H;
    }

    @include mq-mb {
        padding-top: $HEADER_H - ($M_DIFF + $S_DIFF);
    }
}

.inner {
    @include contentWidth;
}

.content {
    padding-top: 50px;
    @include mq-mb {
        padding-top: 40px;
    }

    &-wrap {
        padding-bottom: 40px;
    }
}


/* ========================================
    flex
======================================== */
.flex {
    @include flex;

    &-pc {
        @include mq-pc {
            @include flex;
        }
    }

    &-mb {
        @include mq-mb {
            @include flex;
        }
    }
}


/* ========================================
    table
======================================== */
.table {
    width: 100%;

    th,
    td {
        border: 2px solid $C_BG;

        @include mq-pc {
            padding: 15px 10px;
        }

        @include mq-mb {
            display: block;
        }
    }

    th {
        background-color: $C_MAIN;
        color: $C_BG;
    }

    td {
        @include mq-mb {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $C_GRAY;
        }
    }

    &__title {
        background-color: $C_MAIN2;
        color: $C_MAIN6;
    }
}


/* ========================================
    frame
======================================== */
%frame {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;

    @include mq-pc {
        padding: 30px;
    }

    @include mq-mb {
        padding: 10px;
    }
}

// ボーダー付き
.border-container {
    @extend %frame;
    border: 1px solid $C_MAIN;
}

// 背景付き
.bg-container {
    @extend %frame;
    border: 1px solid $C_MAIN;
    background-color: $C_MAIN;
    color: $C_BG;
}

// 注意書き
.attention-area {
    @extend %frame;
    border: 1px dashed $C_MAIN;
}


/* ========================================
    mask more
======================================== */
.mask {
    &-wrap {
        position: relative;

        .mask-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -80px;
            max-width: 300px;
            margin: auto;
            text-align: center;
            color: $C_MAIN;
            font-weight: bold;

            .pl {
                position: relative;
                display: inline-block;
                margin-right: 10px;
                width: 32px;
                height: 32px;
                border: 1px solid $C_MAIN;
                border-radius: 100%;
                vertical-align: middle;
            
                &:before, 
                &:after {
                    content: '';
                    @include positionCenter;
                    width: 50%;
                    height: 1px;
                    background-color: $C_MAIN;
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    &-item {
        position: relative;
        overflow: hidden;
        max-height: 120px;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 80px;
        padding-left: 20px;
        padding-right: 20px;
        mask-image: linear-gradient($C_FONT 0em, $C_FONT calc(100% - 10em), transparent);
    }

    &-trigger {
        display: none;

        &:checked + .mask-btn {
            visibility: hidden;
            opacity: 0;
        }

        &:checked ~ .mask-item {
            margin-bottom: 0;
            max-height: inherit;
            mask-image: none;
        }
    }
}


/* ========================================
    hidden box
======================================== */
.hidden {
    &-wrap {
        position: relative;
    }

    &-item {
        display: none;
    }

    &-trigger {
        display: none;

        &:checked ~ .hidden-item {
            display: block;
        }
    }
}


/* ========================================
    tab
======================================== */
.tab {
    &-wrap {
        margin-bottom: 40px;
        width: 100%;
    }

    &-category {
        display: flex;
        font-size: 1.2rem;
        max-width: 100%;
    }

    &-category__item {
        padding: 5px 20px;
        color: $C_GRAY3;
        background-color: $C_GRAY;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 0.08em;
        @include transition;
        cursor: pointer;

        &:not(:last-child) {
            border-right: 1px solid $C_GRAY3;
        }

        &.active {
            color: $C_MAIN;
            background-color: $C_GRAY3;
        }
    }
    
    // 記事表示エリア
    &-list {
        &__area {
            display: none;
            padding: 20px;
            background-color: $C_GRAY3;

            &.active {
                display: block;
                opacity: 1;
                animation-name: fadein;
                animation-duration: 1s;
            }
        }
    }
}