/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */

/* ========================================
    global
        大見出し
======================================== */
.section-title {
    margin-bottom: 40px;
    font-size: 4rem;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;
    line-height: 1;
    color: $C_MAIN;

    @include mq-mb {
        font-size: 2.6rem;
    }
}

/* ========================================
    main
        中見出し
======================================== */
.main-title {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid $C_GRAY2;
    font-size: 1.8rem;
    font-family: $ENG_FONT_FAMILY;
    line-height: 1;
}

/* ========================================
    sub
        子見出し
======================================== */
.sub-title {
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;
    line-height: 1;
}


.border-title {
    font-size: 1.8rem;
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $C_GRAY2;
}