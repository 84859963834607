/* ========================================

    pager
        ページャーのパーツ

    INDEX
        - pager

======================================== */

/* ========================================
    pager
======================================== */
%pager-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $ARROW_SIZE;
    height: $ARROW_SIZE;
    background-color: $C_MAIN;
    color: $C_WHITE;
}

.pager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
    max-width: 260px;

    // 戻る
    .btn-back {
        @extend %pager-btn;
    }

    // 一覧へ
    .btn-list {
        position: relative;
        width: 30px;
        height: 30px;

        &__inner {
            @include center;
        }

        &__item {
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 3px;
            width: 3px;
            height: 3px;
            background-color: $C_BLACK;
            text-align: center;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                display: inline-block;
                width: 3px;
                height: 3px;
                background-color: $C_BLACK;
            }

            &:before {
                left: -6px
            }
            
            &:after {
                right: -6px
            }
        }
    }
    
    // 次へ
    .btn-next {
        @extend %pager-btn;
    }

    // 番号付きページング
    .btn-num {
        display: flex;

        &__item {
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;

            a,
            span {
                display: block;
                margin: 10px;
                color: $C_BLACK;
            }

            .current {
                border-bottom: 2px solid $C_MAIN;
            }
        }
    }

    // ボタンの打ち消し
    .btn-none {
        pointer-events: none;
        opacity: 0;
    }
}
