/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
.footer {
    margin-top: auto;
    
    @include mq-pc {
        padding-top: $FOOTER_H;
    }

    @include mq-mb {
        padding-top: 20px;
    }
    
    &__top {
        background-color: $C_MAIN;

        @include mq-pc {
            height: $FOOTER_T_H;
        }
    }

    &__bottom {
        background-color: #101010;
        height: $FOOTER_B_H;
    }

    &-menu {
        background: $C_SUB1;
        height: 100%;

        @include mq-pc {
            display: flex;
            align-items: center;
        }
    }

    &-list {
        @include mq-pc {
            display: flex;
            justify-content: center;
        }

        @include mq-mb {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        &__item {
            a {
                display: block;
                color: $C_BG;

                @include mq-pc {
                    padding: 20px;
                    text-decoration: underline;
                }

                @include mq-mb {
                    padding: 15px 10px;
                    font-size: 1.2rem;
                    text-decoration: underline;
                }
            }
        }
    }

    .page-top {
        position: absolute;
        bottom: $FOOTER_T_H - $PAGE_TOP + ($PAGE_TOP_BORDER * 2);
        width: $PAGE_TOP;
        height: $PAGE_TOP;
        border: $PAGE_TOP_BORDER solid $C_BG;
        color: $C_BG;

        @include mq-pc {
            right: 40px;
        }

        @include mq-mb {
            right: 30px;
        }

        i {
            @include center;
        }
    }

    .copy {
        display: block;
        padding: 10px;
        color: $C_BG;
        font-size: 1.2rem;
        text-align: center;
    }
}
