/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.main-slider {
    @include mq-pc {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: $MAX_W;
    }

    &__item {
        text-align: center;

        img:not(.blank-img) {
            max-width: $MAX_W - 20;
            width: 100%;
        }
    }

    .swiper {

        &-pagination {

            &-bullet {
                background: $C_WHITE;
                opacity: 0.7;
                margin: 0 9px!important;
                vertical-align: middle;
                
                &-active {
                    opacity: 1;
                    background-image: url(../images/swiper_active.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        &-button {

            @include mq-mb {
                display: none;
            }

            &-next,
            &-prev {
                width: $ARROW_SIZE;
                height: $ARROW_SIZE;
                background-color: $C_MAIN6;
                color: $C_BG;
            }

            &-next {
                right: -($ARROW_SIZE / 2);
            }

            &-prev {
                left: -($ARROW_SIZE / 2);
            }
        }
    }
}

.info-list {
    position: relative;
    margin-top: 30px;
    padding: 10px 30px;
    border-radius: 100px;
    background-color: $C_WHITE;
    border: solid 2px #101010;

    @include mq-mb {
        border-radius: 10px;
        padding: 10px 10px 15px 10px;
    }

    .swiper {
        &-custom-parent {
            @include mq-pc {
                padding-left: 100px;
                padding-right: 100px;
                width: 100%;
            }
        }

        &-button {
            position: absolute;
            right: 0;
            width: 80px;

            @include mq-pc {
                top: 0;
                bottom: 0;
                margin: auto;
                height: 100%;
            }

            @include mq-mb {
                top: -10px;
                right: -15px;
            }

            &-next,
            &-prev {
                color: $C_GRAY;
            }
        }
    }

    &__title {
        font-family: $ENG_FONT_FAMILY;
        font-weight: bold;
        white-space: nowrap;
        color: $C_RED;

        @include mq-pc {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
            padding-right: 20px;
        }

        i {
            padding-right: 10px;
        }
    }

    &__item {
        display: flex;

        @include mq-pc {
            justify-content: center;
        }

        @include mq-mb {
            padding: 12px 0 0 10px;
        }
    }

    &__link {
        color: $C_MAIN;
        display: block;
    }

    &__date {
        padding-right: 20px;
        font-size: 1.3rem;
        font-family: $ENG_FONT_FAMILY;
        font-weight: bold;
        color: $C_MAIN;
    }
}

.banner-slider {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @include mq-mb {
        margin-top: 30px;
    }

    .swiper {
        &-button {
            &-next,
            &-prev {
                color: $C_FONT;
            }

            &-next {
                right: -30px;
            }

            &-prev {
                left: -30px;
            }
        }
    }

    &__item {
        text-align: center;

        &.left-banner {
            &:not(:first-child) {
                margin: 20px auto 0;
            }
        }
    }
}

.no-slide {
    justify-content: center;
    &__item {
        max-width: 280px;
        width: 100%;
        margin: 0 15px;
    }
}

.banner-list {
    margin-bottom: 30px;
    padding: 30px 20px;
    background-color: $C_GRAY3;

    &__item {
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.top_items {
    &.goods {
        margin-top: 105px;
    }
}