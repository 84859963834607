/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - mega
        - breadcrumb
        - side

======================================== */

/* ========================================
    global
======================================== */
.global {
    &-menu {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 85%;
        width: calc(100% - (#{$HEADER_T_H} - 20px));
        background-color: $C_MAIN6;
        height: 100vh;
        @include transition;
        transform: translateX(-100%);
    
        @include mq-mb {
            overflow-y: scroll;
        }
    
        &.js-active {
            transform: translateX(0);
        }
    }

    &-list {
        padding-top: $HEADER_T_H;

        &__item {
            display: none;
            opacity: 0; 
            border-bottom: 1px solid $C_BG;

            &.js-active {
                display: block;
                opacity: 1;
                animation-name: fadein-slide;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }

            &.js-more {
                display: block;
                opacity: 1;
            }
        }

        &__text {
            position: relative;
            display: block;
            padding: 10px 20px;
            color: $C_BG;

            i {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                color: $C_BG;
            }

            &.js-active {
                i {
                    display: none;
                }
            }
        }
    }    

    &-sub {
        &-title {
            padding: 10px 20px;
            background-color: rgba($C_BG, 0.3);
            color: $C_BG;
        }

        &-list {
            border-top: 1px solid $C_BG;

            &__item {
                &:not(:last-child) {
                    border-bottom: 1px dashed $C_BG;
                }
            }
            .global-sub-list {
                border-top-style: dashed;

                &__text {
                    position: relative;
                    display: block;
                    padding: 10px 20px;
                    color: $C_BG;
        
                    i {
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        color: $C_BG;
                    }
                }

                // 入れ子は子カテゴリ
                .global-sub-list {
                    &__text {
                        padding-left: 40px;
                    }
                }
            }
        }
    }
}


.js-more {
    &-body {
        display: none;
        opacity: 0; 

        &.js-active {
            display: block;
            opacity: 1;
            animation-name: fadein-slide;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
    }

    &-back {
        display: block;
        padding: 10px 20px;
        background-color: $C_BG;
    }
}


/* ========================================
    trigger
======================================== */
// 以下から持ってきたハンバーガーメニュー
// https://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
%trigger-base {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
}

.trigger-menu {
    @extend %trigger-base;
    $width_size: 45px;
    $height_size: 40px;
    $border_size: 2px;
    $padding_size: 10px;
    $middle_position: #{($height_size / 2) - ($border_size / 2) - ($padding_size)};
    width: $width_size;
    height: $height_size;
    padding: $padding_size;
    @include center;
    z-index: 1;

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    span {
        @extend %trigger-base;
        position: absolute;
        left: 0;
        width: 100%;
        height: $border_size;
        background-color: $C_BG;

        &:nth-of-type(1) {
            top: 0;
        }
            
        &:nth-of-type(2) {
            top: $middle_position;
        }
            
        &:nth-of-type(3) {
            bottom: 0;
        }
    }

    &.js-active {
        span {
            &:nth-of-type(1) {
                transform: translateY(#{$middle_position}) rotate(-45deg);
            }
            
            &:nth-of-type(2) {
                opacity: 0;
            }
            
            &:nth-of-type(3) {
                transform: translateY(-#{$middle_position}) rotate(45deg);
            }
        }
    }
}


/* ========================================
    sub
======================================== */
.sub-menu {
    position: fixed;
    top: 0;
    right: 0;

    @include mq-mb {
        min-width: 50%;
    }
}

.sub-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__item {
        position: relative;
        width: 33.3%;

        @include mq-pc {
            height: $HEADER_T_H;
        }

        @include mq-mb {
            height: $HEADER_T_H - 20;
            max-width: $HEADER_T_H - 20;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $C_BG;
        text-align: center;
        font-weight: bold;

        button {
            @include mq-mb {
                background: $C_BLACK;
            }
            i {
                @include mq-mb {
                    color: $C_WHITE;
                }
            }
        }

        i {
            font-size: 2rem;

            @include mq-pc {
                padding-right: 10px;
            }
    
        }
    }
}


/* ========================================
    mega
======================================== */
.mega-list {
    display: flex;
    align-items: center;
    height: 100%;

    &__item {
        @include mq-pc {
            margin-right: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include mq-mb {
            margin-right: 20px;
        }
    }

    &__text {
        color: $C_BG;
        font-family: $ENG_FONT_FAMILY;
        font-weight: bold;
        font-size: 1.6rem;
    }

    &__trigger {
        &:hover {
            .mega-list__text {
                @include hover;
            }

            .box-menu {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.box-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background-color: $C_SUB1;
    color: $C_BG;
    @include transition;

    @include mq-pc {
        top: $HEADER_H;
    }
}

.nav {
    &-list {
        &__item {
            a {
                display: block;
                padding: 10px 0;
                color: $C_BG;
            }
        }
    }

    &-sub-list {
        &__item {
            position: relative;
            padding-left: 20px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 5px;
                bottom: 0;
                margin: auto;
                width: 5px;
                height: 1px;
                background-color: $C_BG;
            }
        }
    }
}

/* ========================================
    breadcrumb
======================================== */
.breadcrumb {
    margin-bottom: 66px;

    @include mq-mb {
        margin-bottom: 40px;
    }

    &__item {
        padding-right: 10px;
        color: $C_FONT;

        &:not(:first-child) {
            padding-left: 10px;
        }
    }
}


/* ========================================
    side
======================================== */
.side {
    &-menu {
        @include mq-pc {
            margin-right: 40px;
        }
    }

    &-list {
        border-bottom: 1px solid $C_GRAY2;

        // 入れ子は子カテゴリ
        .side-sub-list {
            border-top: 1px solid $C_GRAY2;

            &__item {
                &:not(:last-child) {
                    border-bottom: 1px dashed $C_GRAY2;
                }
            }

            &__text {
                position: relative;
                display: block;
                padding-top: 20px;
                padding-left: 20px;
                padding-bottom: 20px;
                color: $C_FONT;
    
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 5px;
                    bottom: 0;
                    margin: auto;
                    width: 5px;
                    height: 1px;
                    background-color: $C_FONT;
                }
            }
        }

        &__title {
            font-size: 2.8rem;
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;
            color: #1A2148;
        }

        &__box {
            margin-bottom: 30px;
            padding: 30px 20px;
            border-top: 5px solid #1A2148;
            background-color: $C_GRAY3;
        }

        &__item {
            &:not(:last-child) {
                border-bottom: 1px solid $C_GRAY2;
            }
        }

        &__text {
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
            color: $C_FONT;
        }
    }
}