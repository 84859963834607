/* ========================================
  
    list
        リストのスタイル

    INDEX
        - text list
        - thumb list
        - circle list

======================================== */

.date,
.list__date {
    margin-right: 10px;
    font-size: 1.3rem;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;

    .year,
    .day {
        display: block;
        text-align: right;
    }

    .day {
        font-size: 2em;
    }
}

.icon__new {
    color: $C_RED;
    font-size: 1.3rem;
    font-family: $ENG_FONT_FAMILY;
    font-weight: bold;
    z-index: 1;
}

.tags__item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $C_MAIN;
    color: $C_WHITE;
    font-size: 1.2rem;
    font-weight: bold;
    white-space: nowrap;
}

.soldout-bar {
    font-family: $ENG_FONT_FAMILY;
    line-height: 1;
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    background-color: rgba(#E3E3E3,0.8);
    text-align: center;
    color: $C_RED;
    font-weight: bold;
    padding: 15px 0;
    font-size: 2rem;
}

// ========================================
//  text list
//      テキストのみリスト
// ========================================
.list {
    $this: &;
    display: block;

    &__item {
        margin-bottom: 20px;
        border-bottom: 1px solid $C_GRAY2;
        word-break: break-word;

        a {
            display: block;
            padding-bottom: 20px;
            color: $C_BLACK;
        }
    }

    &__title {
        margin-top: 10px;
        font-weight: bold;
    }

    &__text {
        margin-top: 10px;
        font-size: 1.5rem;
    }

    &__img {
        position: relative;
        border: 1px solid $C_GRAY2;
        width: 100%;
        max-width: 300px;
        box-sizing: content-box;

        .icon__new {
            @include itemIcon($top: -15px, $right: 10px, $width: 40px, $height: 40px, $bgColor: $C_RED, $color: $C_WHITE);
        }

        .soldout__bar {
            @include center;
            width: 100%;
            background-color: rgba($C_GRAY2, 0.8);
            color: $C_RED;
            text-align: center;
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;
            z-index: 1;

            @include mq-pc {
                padding: 5px;
                font-size: 2rem;
            }

            @include mq-mb {
                font-size: 1.4rem;
            }
        }
    }

    // ========================================
    //  thumb list
    //      サムネありリスト
    // ========================================
    &--thumb {
        @extend #{$this};

        #{$this}__img {
            margin-right: 20px;
        }
    }

    // ========================================
    //  photo list
    //      画像メインリスト
    // ========================================
    &--photo {
        @extend #{$this};
        display: flex;
        flex-wrap: wrap;
        
        @include mq-mb {
            justify-content: space-between;
        }

        #{$this}__item {
            margin-bottom: 50px;
            border-bottom: none;

            @include mq-pc {
                width: 30%;

                &:not(:nth-child(3n)) {
                    margin-right: 5%;
                }
            }

            @include mq-mb {
                width: 48%;
            }

            a {
                position: relative;
                height: 100%;
            }

            &__info {
                margin-bottom: 20px;
            }
        }

        #{$this}__img {
            margin-bottom: 15px;
            padding-top: 100%;
            width: 100%;

            img {
                @include center;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        #{$this}__bottom {
            position: absolute;
            bottom: 0;
            color: $C_GRAY;
            font-size: 1.8rem;
            font-family: $ENG_FONT_FAMILY;
            font-weight: bold;
        }
    }

    // ========================================
    //  circle list
    //      丸いサムネありリスト
    // ========================================
    &--circle {
        @extend #{$this};

        #{$this}__item {
            font-size: 12px;
        }
    }

    // ========================================
    //  schedule list
    //      日付が横にくるリスト
    // ========================================
    &--schedule {
        @extend #{$this};

        #{$this}__item {
            border-bottom: none;

            a {
                display: table;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &__l,
            &__r {
                display: table-cell;
                vertical-align: top;
            }

            &__l {
                padding-right: 20px;
                width: 20%;
                border-right: 2px dotted $C_GRAY;
                white-space: nowrap;

                .category__name {
                    margin-right: 0;
                }
            }

            &__r {
                padding-left: 20px;
            }
        }
    }
}





.asterisk-list {
    li {
        display: table;

        &:before {
            content: "※";
            display: table-cell;
            padding-right: 4px;
        }
    }
}

.decimal-list li {
    display: table;
    list-style-type: none;
    counter-increment: number;

    &:before {
        content: ""counter(number) ".";
        display: table-cell;
        padding-right: 4px;
        text-align: right;
    }
}

.disc-list li {
    list-style-type: disc;
    margin-left: 20px;
}